import React from "react";
import styles from "./IntroPage.module.scss";
import Logo from "assets/svg/full-logo.svg";
import classNames from "classnames";
import DefaultButton from "components/buttons/DefaultButton/DefaultButton";
import Footer from "../../components/Footer/Footer";
const IntroPage = () => {
  return (
    <div className="app-padding full-width full-height">
      <div
        className={classNames(
          styles.bgContainer,
          "full-width flex a-items-c intro-bg",
        )}
      >
        <img src={Logo} alt="" className="logo-width" />
      </div>
      <DefaultButton
        text="Start your Studio Pod experience"
        customStyles="intro-btn"
      />
      <Footer customStyles="intro-footer" />
    </div>
  );
};

export default IntroPage;
