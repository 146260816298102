import React, { ButtonHTMLAttributes, FC, memo } from "react";
import classNames from "classnames";

type DefaultButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: (event: any) => void;
  text: string;
  type?: "submit" | "reset" | "button";
  isDisabled?: boolean;
  customStyles?: string;
};

// eslint-disable-next-line react/display-name
const DefaultButton: FC<DefaultButtonProps> = memo(
  ({ isDisabled = false, onClick, text, type = "button", customStyles }) => {
    const btnClass = classNames(
      "btn",
      {
        disabledBtn: isDisabled,
      },
      customStyles,
    );

    return (
      <button
        onClick={onClick}
        type={type}
        className={btnClass}
        disabled={isDisabled}
      >
        {text}
      </button>
    );
  },
);

export default DefaultButton;
