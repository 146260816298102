import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import IntroPage from "./views/IntroPage/IntroPage";
import "./styles/styles.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/intro" element={<IntroPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
